/* eslint-disable no-extra-parens */
import styled from 'styled-components'
import { DEVICE, DISPLAY_WIDTH } from '../../util/constants'
import { GreyFill } from './ProductDirectory.styles'
import GoogleAdBlock from '../ContentBlocks/GoogleAdBlock'
import { RegionContainer } from '../styles/Utility.styles'
import LinkWrapper from '../ContentBlocks/LinkWrapper'

export const UpperContentRegion = styled.section`
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;

  @media ${DEVICE.laptopG} {
    flex-direction: row;
  }
`

export const FeaturedArticleImageContainer = styled.div`
  margin: 22px 0 11px 0;

  @media ${DEVICE.tabletL} {
    margin: 0 30px 19px 0;
  }
`

export const FeaturedContentRegion = styled.div`
  display: flex;
  flex-direction: column;

  @media ${DEVICE.laptopG} {
    flex-basis: 740px;
  }
`

export const FeaturedArticleTitle = styled.div`
  h1 {
    color: var(--highlight-blue);
    font-size: 2.8rem;
    font-weight: 300;
    font-style: normal;
    line-height: 1.35;

    @media ${DEVICE.tabletL} {
      font-size: 4.8rem;
      margin-right: 30px;
    }
  }
`

export const FeaturedArticleBodyText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.35;
  color: var(--content-background-dark-blue);
  padding-top: 12px;

  @media ${DEVICE.tabletL} {
    font-size: 2.4rem;
    margin-right: 30px;
  }
`

export const RecentArticleColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media ${DEVICE.laptopG} {
    /* NOTE: This justify-content was added when second Google Ad in column
    was removed as per client request. Should drop it if they switch back */
    justify-content: flex-start;
    align-items: flex-start;
  }
`

export const ArticleFilterSectionTitle = styled.h2`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 2.8rem;
  line-height: 1.35;
  color: var(--highlight-blue);
  padding-top: 38px;
  padding-left: 32px;

  @media ${DEVICE.tabletL} {
    padding-left: unset;
    font-size: 3.6rem;
  }
`

export const FilterControlRegion = styled.div`
  grid-column: 1 / 4;
  display: flex;
  flex-direction: column;

  @media ${DEVICE.tabletL} {
    grid-column: 2 / 3;
  }
`

export const FilterControlRow = styled.div`
  @media screen and (max-width: ${DISPLAY_WIDTH.tabletL - 1}px) {
    display: none;
  }

  @media ${DEVICE.tabletL} {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    flex-direction: row;
    height: 48px;
    align-items: unset;
  }
`

export const FilterControlColumn = styled.div`
  @media screen and (max-width: ${DISPLAY_WIDTH.tabletL - 1}px) {
    grid-column: 1 / 4;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: ${({ isOpen }) => (!isOpen ? '60px' : '360px')};
    transition: height 0.15s ease-out;
    background: var(--highlight-blue);
    margin-top: 14px;
    padding: 0 32px;
    overflow: hidden;
  }

  @media ${DEVICE.tabletL} {
    display: none;
  }
`

export const FilterColumnButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 60px;
  justify-content: space-between;
  align-items: center;
`

export const ArticleAreaGreyFill = styled(GreyFill)`
  margin-top: 0;
`

export const GridAndPaginationContainer = styled(RegionContainer)`
  padding-bottom: 20px;
`

export const ArticleGrid = styled.div`
  grid-column: 2 / 3;
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (min-width: 870px) {
    grid-template-columns: repeat(auto-fill, 311px);
    column-gap: 16px;
    justify-content: left;
  }

  @media screen and (min-width: 1350px) {
    column-gap: 32px;
    grid-template-columns: repeat(auto-fill, 311px);
    justify-content: space-between;
  }
`

// GOOGLE ADS
export const TopLineGoogleAd = styled(GoogleAdBlock)`
  padding-bottom: 0;
  margin: 36px 0 24px 0;

  @media ${DEVICE.laptopG} {
    margin-right: 30px;
    margin-bottom: 0;
  }
`

export const SidebarGoogleAdOne = styled(GoogleAdBlock)`
  /* padding-bottom: 32px; */
  margin-bottom: 24px;
`

export const SidebarGoogleAdTwo = styled(GoogleAdBlock)`
  padding-bottom: 0;
`

export const ArticleGridGoogleAd = styled(GoogleAdBlock)`
  justify-content: center;
  margin-bottom: 24px;

  @media ${DEVICE.tabletL} {
    justify-content: flex-start;
    margin-bottom: unset;
  }
`

export const BottomLineGoogleAd = styled(GoogleAdBlock)`
  padding-bottom: 0;
`

export const FilterControlButtonMobile = styled.button`
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.71;
  letter-spacing: 0.02em;
  background-color: unset;
  color: white;
`

export const FilterControlButtonDesktop = styled(FilterControlButtonMobile)`
  background-color: white;
  flex-basis: 123px;
  height: auto;
  color: ${({ isActive }) =>
    isActive ? 'var(--highlight-blue)' : 'var(--content-background-dark-blue)'};
  border-bottom: ${({ isActive }) =>
    isActive ? '4px solid var(--highlight-blue)' : '4px solid white'};
  border-bottom-width: 8px;

  :hover {
    color: var(--highlight-blue);
  }
`
