import styled from 'styled-components'
import { DEVICE } from '../../../util/constants'
import LinkWrapper from '../LinkWrapper'

export const ArticleRegion = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 337px;
`

export const ArticleImage = styled.img`
  width: 311px;
  height: 216px;
  object-fit: none;
`

export const DateField = styled.h5`
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.71;
  letter-spacing: 0.02em;
  color: var(--highlight-blue);
  margin: 20px 0 12px 0;
`

export const Title = styled.div`
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 2.4rem;
    line-height: 1.375;
    color: var(--content-background-dark-blue);
  }
`

export const BodyText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 1.375;
  color: var(--content-background-dark-blue);
`

export const additionalCtaStyles = `

  @media ${DEVICE.tabletL} {
    padding-bottom: 58px;
  }
`
