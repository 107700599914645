import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import AdvanceLanding from '../components/AdvanceLanding'
import AdvanceArticleHero from '../components/Heroes/AdvanceArticleHero'
import { paginationChange } from '../util/helper'

const AdvanceLandingPage = ({ data }) => {
  const doc = data.prismicAdvanceLandingPage.data
  const types = data.allPrismicAdvanceArticleType.edges
  if (!doc || !types) {
    return null
  }
  const headerText = doc.page_title ? doc.page_title : ''
  const heroText = doc.body_text ? doc.body_text : ''
  const twitterImage = doc.twitter_image?.url
    ? doc.twitter_image
    : doc.featured_post.hero_image?.url
    ? doc.featured_post.hero_image
    : null
  const facebookImage = doc.facebook_image?.url
    ? doc.facebook_image
    : doc.featured_post.hero_image?.url
    ? doc.featured_post.hero_image
    : null
  const seoTitle = doc.meta_title?.text
    ? doc.meta_title.text
    : doc.page_title?.text
    ? doc.page_title.text
    : 'Advance Online'
  const seo_embed_code = doc.seo_embed_code?.text ?? null

  return (
    <>
      <SEO
        seo={{
          description: doc.meta_description,
          keywords: doc.meta_keywords,
          title: seoTitle,
          twitter_image: twitterImage,
          facebook_image: facebookImage,
          seo_embed_code: seo_embed_code,
        }}
      />
      <Layout>
        <AdvanceArticleHero
          header={headerText}
          heroText={heroText}
          heroImage={null}
          showPageTitle={false}
          showBackButton={false}
        />
        <AdvanceLanding
          doc={doc}
          types={types}
          paginationChangeAction={paginationChange}
        />
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    prismicAdvanceLandingPage {
      uid
      data {
        meta_title {
          text
        }
        meta_keywords
        meta_description
        body_text {
          richText
        }
        page_title {
          richText
          text
        }
        seo_embed_code {
          text
        }
        featured_post {
          document {
            ... on PrismicAdvanceArticle {
              id
              uid
              type
              data {
                header {
                  richText
                }
                intro_text {
                  richText
                }
                hero_image {
                  alt
                  url
                  thumbnails {
                    landing_page_featured {
                      url
                    }
                    mobile {
                      url
                    }
                    recent_article_list {
                      url
                    }
                  }
                }
              }
            }
          }
        }
        top_line_google_ad {
          document {
            ... on PrismicGoogleAdTag {
              id
            }
          }
        }
        sidebar_google_ad_one {
          document {
            ... on PrismicGoogleAdTag {
              id
            }
          }
        }
        sidebar_google_ad_two {
          document {
            ... on PrismicGoogleAdTag {
              id
            }
          }
        }
        bottom_line_google_ad {
          document {
            ... on PrismicGoogleAdTag {
              id
            }
          }
        }
        article_grid_google_ad {
          document {
            ... on PrismicGoogleAdTag {
              id
            }
          }
        }
      }
    }
    allPrismicAdvanceArticleType(
      sort: { fields: data___sort_order, order: ASC }
    ) {
      edges {
        node {
          id
          data {
            type
          }
        }
      }
    }
  }
`

export default AdvanceLandingPage
