import React from 'react'
import { PrismicRichText } from '@prismicio/react'

import CtaLink from './CtaLink'
import * as Styled from './styles/AdvanceArticleGridItem.styles'
import { formatDate } from './../../util/timeDateUtils'
import LinkWrapper from './LinkWrapper'

import DEFAULT_HERO_IMAGE from '../../images/logo-over-blue-337x215.png'

const AdvanceArticleGridItem = ({ item }) => (
  // <pre>{JSON.stringify(item, false, 2)}</pre>
  <Styled.ArticleRegion>
    <LinkWrapper link={item}>
      <Styled.ArticleImage
        src={
          item.data.hero_image?.thumbnails?.landing_page_grid?.url
            ? item.data.hero_image.thumbnails.landing_page_grid.url
            : item.data.hero_image
            ? item.data.hero_image.url
            : DEFAULT_HERO_IMAGE
        }
        alt={item.data.hero_image ? item.data.hero_image.alt : 'NPF Logo'}
      />
    </LinkWrapper>
    <Styled.DateField>{formatDate(item.data.publish_date)}</Styled.DateField>
    <Styled.Title>
      <PrismicRichText field={item.data.header.richText} />
    </Styled.Title>
    <Styled.BodyText>
      <PrismicRichText field={item.data.intro_text.richText} />
    </Styled.BodyText>
    <CtaLink
      text="Read Article"
      link={{ ...item, link_type: 'Document' }}
      additionalStyles={Styled.additionalCtaStyles}
    />
  </Styled.ArticleRegion>
)

export default AdvanceArticleGridItem
